// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import 'bootstrap-datepicker'
import "@fortawesome/fontawesome-free/css/all"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
var jQuery = require('jquery')
import 'select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import 'jquery-autogrow-textarea/dist/jquery.autogrow.min.css'
import 'jquery-autogrow-textarea/dist/jquery.autogrow.min.js'
import 'trix/dist/trix.css'
require("@nathanvda/cocoon")
import 'datatables.net-bs4/js/dataTables.bootstrap4'

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../stylesheets/application'
import './demo/chart-area-demo'
import './demo/chart-bar-demo'
import './demo/chart-pie-demo'

require.context('../images', true)

$(document).on('turbolinks:load', function() {
  functionToRun(jQuery)
})

$(document).ajaxComplete(function() {
  functionToRun(jQuery)
})

// Delete old select2 picker stuff before initializing again (issue when clicking back)
$(document).on('turbolinks:before-cache', function() {
  $('.select2-picker').select2('destroy')
  $('.datatable').DataTable().destroy()
})

const functionToRun = (function($) {
  "use strict"; // Start of use strict

  // Close datepicker after selection
  $('input[data-provide="datepicker"]').on('changeDate', function(ev) {
    $(this).datepicker('hide')
  })

  $('.datatable').DataTable({
    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
    "pageLength": -1,
    "order": [[3, "desc"], [1, "asc"]]
  })

  $('.select2-picker').select2({
    theme: 'bootstrap4'
  })

  $('.select2-multi-picker').select2({
    theme: 'bootstrap4',
    multiple: true
  })

  // Sortable questions
  const el = document.getElementById('sortableQuestions')
  if (typeof el !== 'undefined' && el !== null) {
    Sortable.create(el, {
      handle: '.fa-arrows-alt',
      animation: 150
    })
  }

  $('.autogrow').autogrow()
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function() {
    if ($(window).width() < 768) {
      $('.sidebar .collapse').collapse('hide');
    };
    
    // Toggle the side navigation when window is resized below 480px
    if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
      $("body").addClass("sidebar-toggled");
      $(".sidebar").addClass("toggled");
      $('.sidebar .collapse').collapse('hide');
    };
  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function(e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function() {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function(e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
  });
}); // End of use strict

require("trix")
require("@rails/actiontext")